<template>
  <div class="basic-info__container">
    <loading v-if="form.loadingData" class="loading-data" />
    <v-form
      lazy-validation
      :class="{ 'loading-form': form.loadingData }"
      ref="basicForm"
      @submit.prevent="submit"
    >
      <v-row>
        <!-- <v-col cols="3" class="pr-10 d-none d-lg-block">
          <div class="h-75 w-100 position-relative mt-2">
            <img
              :src="sideImage"
              alt="super model image"
              class="h-100 w-100 rounded"
              style="object-fit: cover"
            />
            <div class="profile__image--label text-uppercase position-absolute">
              our super model:
            </div>
            <div class="profile__image--text text-white position-absolute">
              Sofia Johnson
            </div>
          </div>
        </v-col> -->
        <!-- <v-col cols="12" lg="9" class="pr-md-3"> -->
        <v-col cols="12" class="pr-0 pr-md-3">
          <SectionTitle :title="`${$_t('step')} 1: ${$_t('step1.text')}`" />
          <v-row class="mt-4">
            <v-col cols="12" md="2" class="pr-0">
              <div class="profile__image h-100">
                <image-cropper
                  ref="cropper"
                  class="w-100 h-100 rounded"
                  :url.sync="form.model.thumbnail"
                  v-model="form.fileForm"
                  :alt="$_t('profile')"
                  :label="$_t('profile')"
                  :rules="firstRulesCropper"
                  required
                />
              </div>
            </v-col>
            <v-col cols="12" md="10" class="pl-5 pr-0 pr-md-3">
              <v-row  v-if="$_getlocaleParam() === 'en'">
                <v-col
                  cols="12"
                  md="3"
                  class="pt-0 mt-0 pl-0 pl-md-3 pr-0 pr-md-3"
                >
                  <v-text-field
                    class="pt-0"
                    :label="$_t('firstName')"
                    color="SonicSilver"
                    v-model="form.first_name"
                    dense
                    outlined
                    :rules="enLangNameRule"
                    required
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                  class="pt-0 mt-0 pr-0 pr-md-3 pl-0 pl-md-3"
                >
                  <v-text-field
                    class="pt-0"
                    :label="$_t('lastName')"
                    color="SonicSilver"
                    v-model="form.last_name"
                    :rules="enLangNameRule"
                    required
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                  class="pt-0 mt-0 pl-0 pl-md-3 pr-0 pr-md-3"
                >
                  <v-text-field
                    class="pt-0"
                    :label="$_t('japaneseLastName')"
                    color="SonicSilver"
                    v-model="form.last_name_jpn"
                    dense
                    outlined
                    :rules="jpLangRule"
                    required
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                  class="pt-0 mt-0 pr-0 pr-md-3 pl-0 pl-md-3"
                >
                  <v-text-field
                    class="pt-0"
                    :label="$_t('japaneseFirstName')"
                    color="SonicSilver"
                    v-model="form.first_name_jpn"
                    dense
                    outlined
                    :rules="jpLangRule"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row  v-else>
                <v-col
                    cols="12"
                    md="3"
                    class="pt-0 mt-0 pr-0 pr-md-3 pl-0 pl-md-3"
                >
                  <v-text-field
                      class="pt-0"
                      :label="$_t('lastName')"
                      color="SonicSilver"
                      v-model="form.last_name_jpn"
                      :rules="jpLangRule"
                      required
                      dense
                      outlined
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                  class="pt-0 mt-0 pl-0 pl-md-3 pr-0 pr-md-3"
                >
                  <v-text-field
                    class="pt-0"
                    :label="$_t('firstName')"
                    color="SonicSilver"
                    v-model="form.first_name_jpn"
                    dense
                    outlined
                    :rules="jpLangRule"
                    required
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                  class="pt-0 mt-0 pr-0 pr-md-3 pl-0 pl-md-3"
                >
                  <v-text-field
                    class="pt-0"
                    :label="$_t('japaneseFirstName')"
                    color="SonicSilver"
                    v-model="form.first_name"
                    dense
                    outlined
                    :rules="enLangNameRule"
                    required
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="3"
                    class="pt-0 mt-0 pl-0 pl-md-3 pr-0 pr-md-3"
                >
                  <v-text-field
                      class="pt-0"
                      :label="$_t('japaneseLastName')"
                      color="SonicSilver"
                      v-model="form.last_name"
                      dense
                      outlined
                      :rules="enLangNameRule"
                      required
                  ></v-text-field>
                </v-col>
              </v-row>
              <div class="custom-with">
                <v-row class="mt-1">
                  <v-col
                    cols="12"
                    md="3"
                    class="pt-0 mt-0 pl-0 pl-md-3 pr-0 pr-md-3"
                  >
                    <date-input
                      :label="$_t('birthday')"
                      color="SonicSilver"
                      v-model="form.birthday"
                      :rules="requiredRules"
                      required
                      dense
                      outlined
                      :max="today"
                    ></date-input>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                    class="pt-0 mt-0 pl-0 pl-md-3 pr-0 pr-md-3"
                  >
                    <v-autocomplete
                      class="pt-0 mt-0"
                      :label="$_t('nationality')"
                      color="SonicSilver"
                      :items="getEthnics"
                      :rules="requiredRules"
                      required
                      :item-text="
                        $_getlocaleParam() === 'en' ? 'name' : 'name_jpn'
                      "
                      item-value="id"
                      v-model="form.ethnic_id"
                      dense
                      outlined
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                    class="pt-0 mt-0 pr-0 pr-md-3 pl-0 pl-md-3"
                  >
                    <v-switch
                      small
                      color="black"
                      inset
                      class="social__switch mt-1"
                      v-model="form.model.is_hybrid"
                      dense
                      outlined
                    >
                      <template v-slot:label>Bi-nationality</template>
                    </v-switch>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                    class="pt-0 mt-0 pr-0 pr-md-3 pl-0 pl-md-3"
                  >
                    <v-autocomplete
                      class="py-0 mt-0"
                      v-model="form.gender_id"
                      :item-text="
                        $_getlocaleParam() === 'en' ? 'title' : 'title_jpn'
                      "
                      item-value="id"
                      :items="getGenders"
                      :label="$_t('gender')"
                      color="SonicSilver"
                      :rules="requiredRules"
                      required
                      dense
                      outlined
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </div>
              <div class="custom-width">
                <v-row>
                  <v-col cols="12" class="pt-0 pr-0 pr-md-3 pl-0 pl-md-3">
                    <v-autocomplete
                      class="pt-0 multiple"
                      color="SonicSilver"
                      :items="getModelTypes"
                      item-value="id"
                      :item-text="
                        $_getlocaleParam() === 'en' ? 'name' : 'name_jpn'
                      "
                      v-model="form.model_types"
                      :rules="modelTypesRules"
                      required
                      attach
                      chips
                      :label="$_t('modelTypes')"
                      multiple
                      dense
                      outlined
                      return-object
                      item-color="black"
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          class="mt-1 wa__f__m__l"
                          label
                          small
                          :input-value="data.selected"
                          color="grey lighten-3"
                        >
                          {{
                            data.item[
                              $_getlocaleParam() === "en" ? "name" : "name_jpn"
                            ]
                          }}
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </div>
              <div class="custom-width">
                <v-row
                  class="mt-0"
                  v-for="index in form.languagesWithLevel.length"
                  :key="index"
                >
                  <v-col cols="12" class="pt-1 pr-0 pr-md-3 pl-0 pl-md-3">
                    <v-card flat class="add__language">
                      <v-row class="my-0">
                        <v-col cols="12" md="5" class="py-2 py-md-0 pr-3">
                          <v-autocomplete
                            color="SonicSilver"
                            class="mt-1 pt-0 pt-md-3"
                            :label="$_t('language')"
                            :items="getLanguages"
                            v-model="form.languagesWithLevel[index - 1].id"
                            :rules="requiredRules"
                            required
                            item-value="id"
                            :item-text="
                              $_getlocaleParam() === 'en' ? 'name' : 'name_jpn'
                            "
                            dense
                            outlined
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="4" class="py-2 py-md-0 pr-3">
                          <v-autocomplete
                            color="SonicSilver"
                            class="mt-1 pt-0 pt-md-3"
                            :label="$_t('level')"
                            :items="getLanguageLevels"
                            item-value="id"
                            :item-text="
                              $_getlocaleParam() === 'en' ? 'name' : 'name_jpn'
                            "
                            v-model="
                              form.languagesWithLevel[index - 1].level_id
                            "
                            :rules="requiredRules"
                            required
                            dense
                            outlined
                          ></v-autocomplete>
                        </v-col>
                        <v-col
                          cols="12"
                          md="3"
                          class="
                            pt-0 pt-md-3
                            mb-4
                            align-center
                            d-flex
                            justify-end
                          "
                        >
                          <block-button
                            :text="$_t('remove')"
                            height="20"
                            class="px-4"
                            color="red"
                            bg-color="bgLightPink"
                            @click.native="removeLanguageRow(index)"
                          ></block-button>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
              <div class="w-80 custom-width">
                <v-row class="mt-0">
                  <v-col cols="12 text-right">
                    <block-button
                      :text="$_t('add')"
                      height="20"
                      class="mr-0 mr-md-4 px-7"
                      color="cyan"
                      bg-color="bgCyan"
                      @click.native="addLanguageRow"
                    ></block-button>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="text-justify">
              <v-textarea
                class="pt-0"
                rows="3"
                :label="$_t('jobExperience')"
                v-model="form.model.job_experience"
                color="SonicSilver"
                :rules="requiredRules"
                v-if="$_getlocaleParam() === 'en'"
                :hint="$_t('jobExperienceHint')"
                required
                dense
                outlined
              ></v-textarea>
              <v-textarea
                  class="pt-0"
                  rows="3"
                  :label="$_t('jobExperience')"
                  v-model="form.model.job_experience"
                  color="SonicSilver"
                  v-else
                  :hint="$_t('jobExperienceHint')"
                  required
                  dense
                  outlined
              ></v-textarea>
            </v-col>
            <v-col cols="6" class="text-justify">
              <v-textarea
                  class="pt-0"
                  rows="3"
                  :label="'japanese '+$_t('jobExperience')"
                  v-model="form.model.job_experience_jpn"
                  color="SonicSilver"
                  :rules="jpLangNoRequiredRule"
                  :hint="$_t('jobExperienceHint')"
                  v-if="$_getlocaleParam() === 'en'"
                  dense
                  outlined
              ></v-textarea>
              <v-textarea
                  class="pt-0"
                  rows="3"
                  :label="'japanese '+$_t('jobExperience')"
                  v-model="form.model.job_experience_jpn"
                  color="SonicSilver"
                  :rules="jpLangRule"
                  :hint="$_t('jobExperienceHint')"
                  v-else
                  dense
                  outlined
              ></v-textarea>
            </v-col>
            <v-col cols="6" class="text-justify">
              <v-textarea
                class="pt-0"
                color="SonicSilver"
                rows="3"
                :label="$_t('bio')"
                v-model="form.bio"
                v-if="$_getlocaleParam() === 'en'"
                :rules="requiredRules"
                :hint="$_t('bioHint')"
                required
                dense
                outlined
              ></v-textarea>
              <v-textarea
                class="pt-0"
                color="SonicSilver"
                rows="3"
                :label="$_t('bio')"
                v-model="form.bio"
                v-else
                :hint="$_t('bioHint')"
                required
                dense
                outlined
              ></v-textarea>
            </v-col>
            <v-col cols="6" class="text-justify">
              <v-textarea
                  class="pt-0"
                  color="SonicSilver"
                  rows="3"
                  :label="'japanese '+$_t('bio')"
                  v-model="form.bio_jpn"
                  v-if="$_getlocaleParam() === 'en'"
                  :rules="jpLangNoRequiredRule"
                  :hint="$_t('bioHint')"
                  dense
                  outlined
              ></v-textarea>
              <v-textarea
                  class="pt-0"
                  color="SonicSilver"
                  rows="3"
                  :label="'japanese '+$_t('bio')"
                  v-model="form.bio_jpn"
                  v-else
                  :rules="jpLangRule"
                  :hint="$_t('bioHint')"
                  dense
                  outlined
              ></v-textarea>
            </v-col>
            <v-col cols="6" class="text-justify">
              <v-textarea
                class="pt-0"
                rows="3"
                :label="$_t('skills')"
                v-model="form.model.skills"
                v-if="$_getlocaleParam() === 'en'"
                color="SonicSilver"
                :rules="requiredRules"
                required
                dense
                outlined
              ></v-textarea>
              <v-textarea
                class="pt-0"
                rows="3"
                :label="$_t('skills')"
                v-model="form.model.skills"
                v-else
                color="SonicSilver"
                required
                dense
                outlined
              ></v-textarea>
            </v-col>
            <v-col cols="6" class="text-justify">
              <v-textarea
                class="pt-0"
                rows="3"
                :label="'japanese '+$_t('skills')"
                v-model="form.model.skills_jpn"
                color="SonicSilver"
                v-if="$_getlocaleParam() === 'en'"
                :rules="jpLangNoRequiredRule"
                dense
                outlined
              ></v-textarea>
              <v-textarea
                class="pt-0"
                rows="3"
                :label="'japanese '+$_t('skills')"
                v-model="form.model.skills_jpn"
                color="SonicSilver"
                v-else
                :rules="jpLangRule"
                dense
                outlined
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <div class="dropzone__label">
                UPLOAD YOUR IMAGES, UP TO 20 ITEMS
              </div>
              <div class="upload" :class="{'border-red':!form.dropzone_images.length && form.isSubmitBasicInfo}">
                <div class="w-100 h-100">
                  <Dropzone
                    @success="addImageToList"
                    @remove="removeImg"
                    :max-files="20"
                    :batch-id="form.file_batch_id"
                    defaultMessage="<div class='d-flex align-center'><i class='WMi-image-svgrepo-com dropzone-icon'></i><div class='text-left'><div class='dropzone-title'>UPLOAD YOUR IMAGES, UP TO 20 ITEMS </div> <div class='dropzone-sub-title'> Allowed Formats:JPG,JPEG,PNG | Max FileSize: 20MB </div></div></div>"
                  />
                </div>
              </div>
              <span v-if="!form.dropzone_images.length && form.isSubmitBasicInfo" class="red--text">
                {{$_trans("validation.err_upload_image_msg")}}
              </span>
            </v-col>
            <v-col cols="12">
              <div class="d-flex justify-left wa__gallery items">
                <div
                  v-for="image in form.dropzone_images"
                  :key="image.id"
                  class="pr-sm-3 mt-3 mt-sm-0 item"
                >
                  <div class="wa__gallery__images">
                    <div
                      class="wa__gallery__image model__image--image"
                      :style="{
                        backgroundImage: `url('${image.thumbnail}')`,
                      }"
                    ></div>
                    <div class="wa__gallery__image__remove">
                      <div class="wa__gallery__image__remove--text">
                        <div class="mt-2">
                          <block-button
                            height="32"
                            text="remove"
                            class="btn-remove w-100 site__button width-auto"
                            @click.native="removeImgDropzone(image)"
                          ></block-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <block-button
                :text="$_t('step1.next')"
                :loading="loadingFile"
                height="30"
                class="btn-next site__button width-auto float-right"
                type="submit"
              ></block-button>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import toast from "@/utils/toast";
import { mapGetters, mapActions } from "vuex";
import SideImage from "../../assets/47cddd0849f5845fbbf9026e22736b9a.jpg";
import SectionTitle from "../Global/Section/SectionTitle.vue";
import ImageCropper from "@/components/Global/Input/ImageCropper";
import Dropzone from "@/components/Global/Input/Dropzone";
import FileRepository from "../../abstraction/repository/FileRepository";
import { requiredRules, modelTypesRules,jpLangNoRequiredRule, jpLangRule,enLangNameRule } from "@/mixins/validate";
import { UserStorage } from "@/utils/storage";
const fileRepository = new FileRepository();
export default {
  components: {
    SectionTitle,
    ImageCropper,
    Dropzone,
  },
  props: ["value", "isSetData"],
  data() {
    return {
      firstRulesCropper: [(v) => !!v || true],
      items: ["Fashion", "Acting", "Extra"],
      sideImage: SideImage,
      loadingFile: false,
      imageCropperRule: [
        () =>
          !!this.form.fileForm.file ||
          this.form.thumbnail !== "https://via.placeholder.com/200x200" ||
          (!!this.form.model.thumbnail && !!this.form.model.thumbnail.length) ||
          "This field is required.",
      ],
      today: new Date().toISOString().slice(0, 10),
    };
  },
  computed: {
    modelTypesRules,
    requiredRules,
    jpLangRule,
    jpLangNoRequiredRule,
    enLangNameRule,
    form: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    ...mapGetters("language", ["getLanguages"]),
    ...mapGetters("modelType", ["getModelTypes"]),
    ...mapGetters("gender", ["getGenders"]),
    ...mapGetters("languageLevel", ["getLanguageLevels"]),
    ...mapGetters("ethnic", ["getEthnics"]),
  },
  watch: {
    isSetData() {
      this.resetForm();
    },
  },
  methods: {
    resetForm() {
      this.$refs.basicForm.resetValidation();
    },
    ...mapActions("language", ["loadLanguages"]),
    ...mapActions("modelType", ["loadModelTypes"]),
    ...mapActions("gender", ["loadGenders"]),
    ...mapActions("languageLevel", ["loadLanguageLevels"]),
    ...mapActions("ethnic", ["loadEthnics"]),
    removeImgDropzone(image) {
      toast.question(this.$_trans("toast.del_gallery_img_item_msg"), this.$_trans("toast.del_gallery_img_item_title"), async () => {
        await fileRepository.destroy(image.id);
        const index = this.form.dropzone_images.findIndex((x) => x.id === image.id);
        this.$delete(this.form.dropzone_images, index);
      });
    },
    addLanguageRow() {
      this.form.languagesWithLevel.push({ level_id: null, id: null });
    },
    removeLanguageRow(index) {
      this.form.languagesWithLevel.splice(index - 1, 1);
      if (this.form.languagesWithLevel.length === 0) {
        this.form.languagesWithLevel.push({ level_id: null, id: null });
      }
    },
    removeImg(fileId) {
      const idx = this.form.dropzone_images.findIndex(
        (img) => img.id === fileId
      );
      this.$delete(this.form.dropzone_images, idx);
    },
    async submit() {
      try {
        this.form.isSubmitBasicInfo = true;
        this.firstRulesCropper = this.imageCropperRule;
        if (
          !this.$refs.basicForm.validate() ||
          this.form.dropzone_images.length === 0
        ) {
          return;
        }
        const hasThumbnail =
          this.form.fileForm.file ||
          (this.form.model.thumbnail &&
            this.form.model.thumbnail !==
              "https://via.placeholder.com/200x200");
        if (!hasThumbnail) {
          return;
        }
        this.loadingFile = true;
        if (this.form.fileForm.file) {
          let repository = new FileRepository({ errorHandle: false });
          let { thumbnail } = await repository.store(this.form.fileForm);
          this.form["model"]["thumbnail"] = thumbnail;
        }
        this.$emit("nextStep");
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingFile = false;
      }
    },
    addImageToList(file) {
      file["image"] = file.url;
      file["thumbnail"] = file.url;
      this.form.dropzone_images.push(file);
    },
    isVerified() {
      return !!UserStorage.get().model && !!UserStorage.get().model.created_at;
    },
    // setValidated() {
    //   this.$refs.basicForm.resetValidation()
    //   if(!this.isVerified()&!this.form.isSetBasicInfo){
    //     this.form.isSetBasicInfo = true;
    //     this.$refs.basicForm.reset()
    //   }
    // }
  },
  created() {
    this.loadModelTypes();
    this.loadGenders();
    this.loadLanguages();
    this.loadLanguageLevels();
    this.loadEthnics();
  },
  mounted() {
    this.$refs.cropper.$children[0].refresh();
  },
};
</script>
<style scoped>
.upload.border-red {
    border: 1px solid red !important;
    border-radius: 5px;
}
.basic-info__container {
  position: relative;
}
.profile__image--text {
  bottom: 10px;
  left: 35px;
  letter-spacing: 3px;
  font-family: "Barlow-EL";
}
.profile__image--label {
  font-family: "Montserrat-regular";
  font-size: 9px;
  color: var(--color-gray);
  bottom: 30px;
  left: 35px;
}
.title {
  color: var(--color-light-gray);
  font-family: "Montserrat-regular" !important;
  font-size: 9px !important;
  height: 22px;
}
.description {
  font-size: 13px;
  font-family: "Montserrat-regular" !important;
}

.profile__image {
  height: 200px;
}
.profile__image img {
  border: 1px dashed var(--color-gray);
}
.custom-width {
  width: 75%;
}

.add__language {
  border: 1px solid var(--color-light-gray);
  padding: 10px 15px 0 15px;
  border-radius: 5px;
}
.btn-next {
  padding-right: 85px !important;
  padding-left: 85px !important;
  font-size: 0.825rem !important;
}
.loading-data {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
}

.loading-form {
  filter: blur(5px);
}
/* gallery */
.dropzone__label {
  font-family: "Montserrat-regular";
  font-size: 16px;
}

.upload {
  border: 1px dotted var(--color-gray) !important;
  position: relative;
}
.wa__gallery__images {
  position: relative;
  height: 260px;
}
.wa__gallery__image {
  border-radius: 5px;
  opacity: 1;
  display: block;
  transition: 0.5s ease;
  backface-visibility: hidden;
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
}
.wa__gallery__image__remove {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  width: 90%;
}
.wa__gallery__images:hover .wa__gallery__image {
  filter: brightness(0.3);
}
.wa__gallery__images:hover .wa__gallery__image__remove {
  opacity: 1;
}
.btn-remove {
  padding: 0 25px !important;
  font-size: 17px !important;
}
.items {
  flex-wrap: wrap;
}
.items .item {
  width: 16.6666666667%;
  margin-bottom: 20px;
}
.items .item:nth-child(6n) {
  padding: 0 !important;
}
@media (max-width: 1200px) {
  .items .item {
    width: 20%;
    margin-bottom: 20px;
  }
  .items .item:nth-child(5n) {
    padding: 0 !important;
  }
  .items .item:nth-child(6n) {
    padding-right: 12px !important;
  }
}
@media (max-width: 960px) {
  .items .item {
    width: 25%;
    margin-bottom: 20px;
  }

  .items .item:nth-child(4n) {
    padding: 0 !important;
  }
  .items .item:nth-child(5n) {
    padding-right: 12px !important;
  }
}
@media (max-width: 768px) {
  .items .item {
    width: 33.33333%;
    margin-bottom: 20px;
  }
  .items .item:nth-child(3n) {
    padding: 0 !important;
  }
  .items .item:nth-child(4n) {
    padding-right: 12px !important;
  }
}
@media (max-width: 480px) {
  .items {
    justify-content: center;
  }
  .items .item {
    width: 100%;
    margin-bottom: 10px;
    padding: 0 !important;
  }
  .items .item:nth-child(3n),
  .items .item:nth-child(4n),
  .items .item:nth-child(5n),
  .items .item:nth-child(6n) {
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 960px) {
  .row {
    margin-right: 0 !important;
  }
  .custom-width {
    width: 100%;
  }
  .add__language {
    height: 100% !important;
    padding-right: 0 !important;
  }
  .loading-data {
    position: absolute;
    left: 50%;
    z-index: 9999;
    transform: translateX(-50%);
    top: 50px;
  }
}
@media screen and (max-width: 600px) {
  .btn-next {
    padding-right: 12px !important;
    padding-left: 12px !important;
    font-size: 0.625rem !important;
  }
  .loading-data {
    position: absolute;
    left: 50%;
    z-index: 9999;
    transform: translateX(-50%);
    top: 50px;
  }
}
</style>
<style>
.social__switch .v-input__control .v-input__slot .v-label {
  font-family: "Montserrat-regular" !important;
  font-size: 15px !important;
}
.vue-dropzone > .dz-preview .dz-remove {
  position: relative !important;
  opacity: 1 !important;
  margin-right: 10px !important;
}
.dropzone .dz-preview.dz-error .dz-error-message {
  display: block;
  opacity: 1;
}
</style>
