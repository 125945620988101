<template>
  <v-form ref="bankForm" @submit.prevent="submit">
    <div class="padding">
      <v-row>
        <!-- <v-col cols="3" class="pr-10 d-none d-lg-block">
          <div class="w-100 position-relative mt-2 profile__image">
            <img
              :src="sideImage"
              alt="super model image"
              class="h-100 w-100 rounded"
              style="object-fit: cover"
            />
            <div class="profile__image--label text-uppercase position-absolute">
              our super model:
            </div>
            <div class="profile__image--text text-white position-absolute">
              Sofia Johnson
            </div>
          </div>
        </v-col> -->
        <!-- <v-col cols="12" lg="9" class="pr-6 pr-md-3"> -->
        <v-col cols="12">
          <SectionTitle :title="`${$_t('step')} 4: ${$_t('step4.textBank')}`" />
          <ul
            class="
              d-flex
              justify-space-between
              pl-0
              list-unstyled
              mt-7
              mb-1 mb-md-2
            "
          >
            <li>
              <v-text-field
                class="pt-0"
                :label="$_t('bankName')"
                color="SonicSilver"
                v-model="form.model.bank_name"
                :rules="requiredRules"
                required
                outlined
                dense
              />
            </li>
            <li>
              <v-text-field
                class="pt-0"
                :label="$_t('branchName')"
                color="SonicSilver"
                v-model="form.model.branch_name"
                :rules="requiredRules"
                required
                outlined
                dense
              />
            </li>
            <li>
              <v-text-field
                class="pt-0"
                :label="$_t('accountNo')"
                color="SonicSilver"
                v-model="form.model.account_no"
                :rules="numericalRules"
                required
                outlined
                dense
              />
            </li>
            <li>
              <v-text-field
                class="pt-0"
                :label="$_t('accountName')"
                color="SonicSilver"
                v-model="form.model.account_name"
                :rules="requiredRules"
                required
                outlined
                dense
              />
            </li>
            <li>
              <v-autocomplete
                class="pt-0 mt-0"
                :label="$_t('accountClassifications')"
                color="SonicSilver"
                :items="getAccountClassifications"
                item-value="id"
                :item-text="$_getlocaleParam()=== 'en'?'name':'name_jpn'"
                v-model="form.model.account_classification_id"
                :rules="requiredRules"
                required
                outlined
                dense
              />
            </li>
          </ul>
          <v-row class="mt-0">
            <v-col cols="12" class="text-justify pt-0">
              <v-textarea
                class="pt-0"
                rows="3"
                :label="$_t('other')"
                color="SonicSilver"
                v-model="form.model.other"
                outlined
                dense
              />
            </v-col>
          </v-row>
          <div v-if="!isCountryJapan">
            <SectionTitle :title="`${$_t('step4.textVisa')}`" />
            <ul class="d-flex pl-0 list-unstyled mt-2 mt-md-3 mb-3 until">
              <li class="pr-0 pr-md-4">
                <v-text-field
                  :label="$_t('typeOfVisa')"
                  color="SonicSilver"
                  class="pt-0 mt-0"
                  v-model="form.model.type_of_visa"
                  :rules="requiredRules"
                  required
                  outlined
                  dense
                />
              </li>
              <li>
                <date-input
                  v-model="form.model.valid_until"
                  :label="$_t('validUntil')"
                  :rules="requiredRules"
                  required
                  color="SonicSilver"
                  outlined
                  dense
                />
              </li>
            </ul>
          </div>
          <v-divider class="mb-2"></v-divider>
          <v-row class="flex-dicoration-column-reverse">
            <v-col
              cols="12"
              sm="6"
              class="text-center text-sm-left pt-0 pt-sm-3"
            >
              <block-button
                :text="$_t('step4.prev')"
                height="30"
                class="btn-prev pl-0"
                icon="WMi-left-open"
                text-mode="text"
                @click.native="$emit('prevStep')"
              />
            </v-col>
            <v-col cols="12" sm="6" class="text-right">
              <block-button
                :text="$_t('step4.next')"
                height="30"
                class="btn-next site__button width-auto float-right"
                type="submit"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import SideImage from "../../assets/stock-photo-227707621.jpg";
import DateInput from "../Global/Input/DateInput.vue";
import SectionTitle from "../Global/Section/SectionTitle.vue";
import { UserStorage } from "@/utils/storage";
import {numberRules,requiredRules,numericalRules} from "@/mixins/validate"
export default {
  components: {
    SectionTitle,
    DateInput,
  },
  props: ["value"],
  data() {
    return {
      sideImage: SideImage,
    };
  },
  computed: {
    ...mapGetters("accountClassification", ["getAccountClassifications"]),
    numberRules,
    requiredRules,
    numericalRules,
    form: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    isCountryJapan() {
      if (this.form.ethnic_id) {
        return this.form.ethnic_id === 1;
      }
      return false;
    },
  },
  methods: {
    ...mapActions("accountClassification", ["loadAccountClassifications"]),
    submit() {
      if (!this.$refs.bankForm.validate()) {
        return;
      }
      this.$emit("nextStep");
    },
    isVerified() {
      return !!UserStorage.get().model && !!UserStorage.get().model.created_at;
    },
  },
  created() {
    this.loadAccountClassifications();
  },
  mounted() {
    if (!this.isVerified() & !this.form.isSetBankInfo) {
      this.form.isSetBankInfo = true;
      this.$refs.bankForm.reset();
    }
  },
};
</script>
<style scoped>
.padding {
  padding-bottom: 140px;
}

.profile__image {
  height: 490px;
}

.profile__image--text {
  bottom: 10px;
  left: 35px;
  letter-spacing: 3px;
  font-family: "Barlow-EL";
}

.profile__image--label {
  font-family: "Montserrat-regular";
  font-size: 9px;
  color: var(--color-gray);
  bottom: 30px;
  left: 35px;
}

.title {
  color: var(--color-light-gray);
  font-family: "Montserrat-regular" !important;
  font-size: 9px !important;
  height: 22px;
}

.description {
  font-size: 13px;
  font-family: "Montserrat-regular" !important;
}

ul li {
  width: 19%;
}

.btn-next {
  padding-right: 85px !important;
  padding-left: 85px !important;
  font-size: 0.825rem !important;
}

.btn-prev {
  font-size: 0.825rem !important;
}
@media screen and (max-width: 960px) {
  ul {
    flex-wrap: wrap;
  }
  ul li {
    width: 49% !important;
  }
  .until li,
  .account li {
    width: 100% !important;
  }
  .btn-prev {
    font-size: 16px !important;
    letter-spacing: 2px !important;
  }
  .btn-next {
    padding-right: 12px !important;
    padding-left: 12px !important;
    font-size: 0.625rem !important;
    width: -webkit-fill-available;
  }
}
@media screen and (max-width: 600px) {
  .flex-dicoration-column-reverse {
    flex-direction: column-reverse;
  }
  .btn-prev {
    letter-spacing: 3px !important;
  }
  .until li,
  .account li {
    width: 100% !important;
  }
  ul li {
    width: 100% !important;
  }
}
</style>
