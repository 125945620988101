<template>
  <v-form ref="physicalForm" @submit.prevent="submit">
    <div class="padding">
      <v-row>
        <!-- <v-col cols="3" class="pr-10 pl-5 d-none d-lg-block">
          <div class="w-100 position-relative mt-2 profile__image">
            <img
              :src="sideImage"
              alt="super model image"
              class="h-100 w-100 rounded"
              style="object-fit: cover"
            />
            <div class="profile__image--label text-uppercase position-absolute">
              super model:
            </div>
            <div class="profile__image--text text-white position-absolute">
              jessi Taylor
            </div>
          </div>
        </v-col> -->
        <!-- <v-col cols="12" lg="9"> -->
        <v-col cols="12">
          <SectionTitle :title="`${$_t('step')} 3: ${$_t('step3.text')}`" />
          <v-row class="mt-5">
            <v-col cols="12" sm="6" md="3">
              <v-autocomplete
                class="pt-0 mt-0"
                :label="$_t('eye')"
                color="SonicSilver"
                :items="getEyeColors"
                item-value="id"
                :item-text="$_getlocaleParam()=== 'en'?'name':'name_jpn'"
                v-model="form.model.eye_color_id"
                :rules="requiredRules"
                required
                dense
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="3" class="pt-0 pt-sm-3">
              <v-autocomplete
                class="pt-0 mt-0"
                :label="$_t('hair')"
                color="SonicSilver"
                :items="getHairColors"
                item-value="id"
                :item-text="$_getlocaleParam()=== 'en'?'name':'name_jpn'"
                v-model="form.model.hair_color_id"
                :rules="requiredRules"
                required
                dense
                outlined
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="12" sm="6" md="2">
              <v-text-field
                class="pt-0 mt-0"
                :label="$_t('weight')"
                color="SonicSilver"
                v-model="form.model.weight"
                :rules="weightRules"
                required
                type="number"
                dense
                outlined
              ></v-text-field
            ></v-col>
            <v-col cols="12" sm="6" md="2">
              <v-text-field
                class="pt-0 mt-0"
                :label="$_t('height')"
                color="SonicSilver"
                v-model="form.model.height"
                :rules="heightRules"
                required
                type="number"
                dense
                outlined
              ></v-text-field
            ></v-col>
            <v-col cols="12" sm="6" md="2">
              <v-text-field
                class="pt-0 mt-0"
                :label="$_t('bust')"
                color="SonicSilver"
                v-model="form.model.bust"
                :rules="bustRules"
                required
                type="number"
                dense
                outlined
              ></v-text-field
            ></v-col>
            <v-col cols="12" sm="6" md="2">
              <v-text-field
                class="pt-0 mt-0"
                :label="$_t('hips')"
                color="SonicSilver"
                v-model="form.model.hips"
                :rules="hipsRules"
                required
                type="number"
                dense
                outlined
              ></v-text-field
            ></v-col>
            <v-col cols="12" sm="6" md="2">
              <v-text-field
                class="pt-0 mt-0"
                :label="$_t('waist')"
                color="SonicSilver"
                v-model="form.model.waist"
                :rules="waistRules"
                required
                type="number"
                dense
                outlined
              ></v-text-field
            ></v-col>
            <v-col cols="12" sm="6" md="2">
              <v-text-field
                class="pt-0 mt-0"
                :label="$_t('shoes')"
                color="SonicSilver"
                v-model="form.model.shoes"
                :rules="shoeRules"
                type="number"
                required
                dense
                outlined
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="12" class="text-justify pt-0">
              <v-textarea
                  rows="2"
                  :label="$_t('tattoo')"
                  v-model="form.model.tattoo"
                  :hint="$_t('tattooHint')"
                  color="SonicSilver"
                  dense
                  outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-divider class="mb-2 mt-7"></v-divider>
          <v-row class="flex-dicoration-column-reverse">
            <v-col cols="12" sm="6" class="text-center text-sm-left pt-0 pt-sm-3">
              <block-button
                :text="$_t('step3.prev')"
                height="30"
                class="btn-prev pl-0"
                icon="WMi-left-open"
                text-mode="text"
                @click.native="$emit('prevStep')"
              ></block-button
            ></v-col>
            <v-col cols="12" sm="6" class="text-right">
              <block-button
                :text="$_t('step3.next')"
                height="30"
                class="btn-next site__button width-auto float-right"
                type="submit"
              ></block-button
            ></v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import SideImage from "../../assets/e9f8de65fff644826d5cb03fa102832b.jpg";
import SectionTitle from "../Global/Section/SectionTitle.vue";
import { UserStorage } from "@/utils/storage";
import {jpLangNoRequiredRule,weightRules,heightRules,bustRules,waistRules,hipsRules,shoeRules,numberRules,requiredRules} from "@/mixins/validate"
export default {
  components: {
    SectionTitle,
  },
  props: ["value"],
  data() {
    return {
      sideImage: SideImage,
    };
  },
  computed: {
    weightRules,
    heightRules,
    bustRules,
    waistRules,
    hipsRules,
    shoeRules,
    numberRules,
    requiredRules,
    jpLangNoRequiredRule,
    form: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    ...mapGetters("eyeColor", ["getEyeColors"]),
    ...mapGetters("hairColor", ["getHairColors"]),
  },
  methods: {
    ...mapActions("eyeColor", ["loadEyeColors"]),
    ...mapActions("hairColor", ["loadHairColors"]),
    submit() {
      if(!this.$refs.physicalForm.validate()) {return}
      this.$emit('nextStep')
    },
    isVerified() {
      return !!UserStorage.get().model&&!!UserStorage.get().model.created_at;
    },
  },
  created() {
    this.loadHairColors();
    this.loadEyeColors();
  },
  mounted() {
    if(!this.isVerified()&!this.form.isSetPhysicalInfo){
      this.form.isSetPhysicalInfo = true;
      this.$refs.physicalForm.reset()
    }
  }
};
</script>
<style scoped>
.padding {
  padding: 0 11px 110px 0;
}
.profile__image {
  height: 490px;
}
.profile__image--text {
  bottom: 10px;
  left: 35px;
  letter-spacing: 3px;
  font-family: "Barlow-EL";
}
.profile__image--label {
  font-family: "Montserrat-regular";
  font-size: 9px;
  color: var(--color-gray);
  bottom: 30px;
  left: 35px;
}
.title {
  color: var(--color-light-gray);
  font-family: "Montserrat-regular" !important;
  font-size: 9px !important;
  height: 22px;
}
.description {
  font-size: 13px;
  font-family: "Montserrat-regular" !important;
}
.btn-next {
  padding-right: 85px !important;
  padding-left: 85px !important;
  font-size: 0.825rem !important;
}
.btn-prev {
  font-size: 0.825rem !important;
}
@media screen and (max-width: 960px) {
  .btn-prev {
    font-size: 16px !important;
    letter-spacing: 2px !important;
  }
  .btn-next {
    padding-right: 12px !important;
    padding-left: 12px !important;
    font-size: 0.625rem !important;
    width: -webkit-fill-available;
  }
}
@media screen and (max-width: 600px) {
  .flex-dicoration-column-reverse {
    flex-direction: column-reverse;
  }
  .btn-prev {
    letter-spacing: 3px !important;
  }
}
</style>
