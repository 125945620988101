<template>
  <main class="pr-2">
    <div>
      <section-title title="BASIC INFO"></section-title>
      <v-row>
        <v-col cols="12" md="2" class="pr-3 pr-md-15">
          <div class="wa__basic__info__image mt-2">
            <img :src="form.model.thumbnail" alt="" />
          </div>
        </v-col>
        <v-col cols="12" md="10" class="pl-3 pl-md-0">
          <v-row class="pt-2">
            <v-col cols="6" sm="4" md="2">
              <RequestDetailsInformation
                title="first name"
                :subTitle="form.first_name"
                class="ma-0"
              />
            </v-col>
            <v-col cols="6" sm="4" md="2"
              ><RequestDetailsInformation
                title="last name"
                :subTitle="form.last_name"
                class="ma-0"
            /></v-col>
            <v-col cols="6" sm="4" md="2">
              <RequestDetailsInformation
                  v-if="form.last_name_jpn !== ''"
                  title="last name japanese"
                  :subTitle="form.last_name_jpn"
                  class="ma-0"
              />
            </v-col>
            <v-col cols="6" sm="4" md="2">
              <RequestDetailsInformation
                  v-if="form.first_name_jpn !== ''"
                  title="first name japanese"
                  :subTitle="form.first_name_jpn"
                  class="ma-0"
              />
            </v-col>
            <v-col cols="12" sm="4" md="8"
              ><RequestDetailsInformation
                title="job experience"
                :subTitle="form.model.job_experience"
                class="ma-0"
            /></v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <RequestDetailsInformation title="bio" :subTitle="form.bio" class="ma-0" />
            </v-col>
            <v-col cols="6">
              <RequestDetailsInformation title="Japanese bio" :subTitle="form.bio_jpn" class="ma-0" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <RequestDetailsInformation
                title="Skills"
                :subTitle="form.model.skills"
                class="ma-0"
              />
            </v-col>
            <v-col cols="6">
              <RequestDetailsInformation
                title="Japanese Skills"
                :subTitle="form.model.skills_jpn"
                class="ma-0"
              />
            </v-col>
            <v-col cols="6">
              <RequestDetailsInformation
                title="tatoo"
                :subTitle="form.model.tattoo"
                class="ma-0"
              />
            </v-col>
            <v-col cols="6">
              <RequestDetailsInformation
                title="Japanese tatoo"
                :subTitle="form.model.tattoo_jpn"
                class="ma-0"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mt-6">
        <v-col cols="12">
          <div class="d-flex justify-left wa__gallery items mt-2">
            <div
              v-for="photo in form.dropzone_images"
              :key="photo.id"
              class="pr-sm-3 mt-3 mt-sm-0 item"
            >
              <div class="wa__basic__info__image__dropzone">
                <div
                  class="wa__basic__info__image--image"
                  :style="{ backgroundImage: `url(${photo.thumbnail})` }"
                ></div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="mt-16">
      <section-title title="general info"></section-title>
      <div class="w-75 w-md-100 pt-2">
        <v-row>
          <v-col cols="12" sm="4" md="2">
            <RequestDetailsInformation
              title="Birthday"
              :subTitle="form.birthday"
              class="ma-0"
            />
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <RequestDetailsInformation
              title="Email Address"
              :subTitle="form.email"
              class="ma-0"
            />
          </v-col>
          <v-col cols="12" sm="4" md="2">
            <RequestDetailsInformation
              title="Cell Phone"
              :subTitle="form.cell_phone"
              class="ma-0"
            />
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <RequestDetailsInformation
              title="instagram ID"
              :subTitle="form.model.instagram_id"
              class="ma-0"
            />
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <RequestDetailsInformation
              title="Line ID"
              :subTitle="form.model.line_id"
              class="ma-0"
            />
          </v-col>
        </v-row>
      </div>
      <div class="w-75 w-md-100">
        <v-row>
          <v-col cols="12" sm="8" md="4">
            <RequestDetailsInformation
              title="Address"
              :subTitle="form.model.address"
              class="ma-0"
            />
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <RequestDetailsInformation
              title="Nearest station"
              :subTitle="form.model.nearest_station"
              class="ma-0"
            />
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <li class="request__details__information">
              <div class="d-block ml-0 ml-md-1 request__details__information__line">
                <div class="request__details__information--title">model types</div>
                <div class="request__details__information--sub-title d-flex">
                  <v-chip
                    class="mr-1 request__details__information--sub-title--block"
                    color="#e6e6e673"
                    text-color="black"
                    label
                    x-small
                    v-for="modelType in form.model_types"
                    :key="modelType.id"
                  >
                    {{ modelType[$_getlocaleParam() === "en" ? "name" : "name_jpn"] }}
                  </v-chip>
                </div>
              </div>
            </li>
          </v-col>
        </v-row>
      </div>
      <div class="w-75 w-md-100">
        <v-row>
          <v-col cols="12" md="6">
            <RequestDetailsInformation
              title="NATIONALITY"
              :subTitle="getNameByid(form.ethnic_id, getEthnics)"
              class="ma-0"
            />
          </v-col>
          <v-col cols="12" md="6">
            <li class="request__details__information">
              <div class="d-block ml-1">
                <div class="request__details__information--title">languages</div>
                <div
                  v-if="languagesWithLevel.length"
                  class="request__details__information--sub-title d-flex"
                >
                  <v-chip
                    class="mr-1 request__details__information--sub-title--block"
                    color="#e6e6e673"
                    text-color="black"
                    label
                    x-small
                    v-for="{ language, level } in languagesWithLevel"
                    :key="language.id"
                  >
                    {{ language[$_getlocaleParam() === "en" ? "name" : "name_jpn"] }} -
                    {{ level[$_getlocaleParam() === "en" ? "name" : "name_jpn"] }}
                  </v-chip>
                </div>
              </div>
            </li>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="mt-16">
      <section-title title="physical info"></section-title>
      <div class="w-50 w-md-75 w-sm-100 pt-2">
        <v-row>
          <v-col cols="12" sm="6" md="2">
            <RequestDetailsInformation
              title="Weight"
              :subTitle="form.model.weight"
              class="ma-0"
            />
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <RequestDetailsInformation
              title="height"
              :subTitle="form.model.height"
              class="ma-0"
            />
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <RequestDetailsInformation
              title="Bust"
              :subTitle="form.model.bust"
              class="ma-0"
            />
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <RequestDetailsInformation
              title="Waist"
              :subTitle="form.model.waist"
              class="ma-0"
            />
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <RequestDetailsInformation
              title="Hips"
              :subTitle="form.model.hips"
              class="ma-0"
            />
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <RequestDetailsInformation
              title="Shoes"
              :subTitle="form.model.shoes"
              class="ma-0"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="2">
            <RequestDetailsInformation title="Hair" :subTitle="hairColor" class="ma-0" />
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <RequestDetailsInformation title="Eye" :subTitle="eyeColor" class="ma-0" />
          </v-col>
          <v-col cols="12" sm="6" md="8">
            <RequestDetailsInformation
              title="gender"
              :subTitle="getNameByid(form.gender_id, getGenders)"
              class="ma-0"
            />
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="mt-16">
      <section-title title="bank info"></section-title>
      <div class="pt-2">
        <v-row>
          <v-col cols="12" sm="6" md="2">
            <RequestDetailsInformation
              title="Bank name"
              :subTitle="form.model.bank_name"
              class="ma-0"
            />
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <RequestDetailsInformation
              title="Branch name"
              :subTitle="form.model.branch_name"
              class="ma-0"
            />
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <RequestDetailsInformation
              title="Account No"
              :subTitle="form.model.account_no"
              class="ma-0"
            />
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <RequestDetailsInformation
              title="Account name"
              :subTitle="form.model.account_name"
              class="ma-0"
            />
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <RequestDetailsInformation
              title="Account classification"
              :subTitle="accountClassification"
              class="ma-0"
            />
          </v-col>
          <v-col v-if="!isCountryJapan" cols="12" sm="6" md="2">
            <RequestDetailsInformation
              title="Type of visa"
              :subTitle="form.model.type_of_visa"
              class="ma-0"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="!isCountryJapan" cols="12" sm="6" md="2">
            <RequestDetailsInformation
              title="Valid until"
              :subTitle="form.model.valid_until"
              class="ma-0"
            />
          </v-col>
          <v-col cols="12" sm="6" md="10">
            <RequestDetailsInformation
              title="Other"
              :subTitle="form.model.other"
              class="ma-0"
            />
          </v-col>
        </v-row>
      </div>
    </div>
    <v-divider></v-divider>
    <v-row class="flex-dicoration-column-reverse">
      <v-col cols="12" sm="6" class="pt-0 pt-sm-3 text-center text-sm-left">
        <block-button
          :text="$_t('step5.prev')"
          height="30"
          class="btn-prev pl-0"
          icon="WMi-left-open"
          text-mode="text"
          @click.native="$emit('prevStep')"
        ></block-button
      ></v-col>
      <v-col cols="12" sm="6" class="text-right">
        <v-btn
            height="30"
            @click="sendRequest"
            :text="$_t('step5.next')"
            depressed
            x-small
            :class="`btn-next site__button width-auto float-right custom-btn color-grey-lighten-3 grey-lighten-3`"
            :block="false"
            type="button"
            :loading="sendLoading"
        >
          {{ $_t('step5.next') }}
        </v-btn>
        </v-col>
    </v-row>
  </main>
</template>
<script>
import SectionTitle from "../Global/Section/SectionTitle.vue";
import RequestDetailsInformation from "../Global/Section/RequestDetailsInformation.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    SectionTitle,
    RequestDetailsInformation,
  },
  props: ["value"],
  data() {
    return {
      login: true,
      title: "",
      subTitle: "",
      // BACK TO HOME
      backRouteName: "models",
      backText: "all models",
      sendLoading: false,
    };
  },
  computed: {
    ...mapGetters("accountClassification", ["getAccountClassifications"]),
    ...mapGetters("gender", ["getGenders"]),
    ...mapGetters("ethnic", ["getEthnics"]),
    ...mapGetters("languageLevel", ["getLanguageLevels"]),
    ...mapGetters("language", ["getLanguages"]),
    ...mapGetters("hairColor", ["getHairColors"]),
    ...mapGetters("eyeColor", ["getEyeColors"]),
    form: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    isCountryJapan() {
      return this.form.ethnic_id === 1;
    },
    eyeColor() {
      if (this.getEyeColors) {
        return this.getEyeColors.find((x) => x.id === this.form.model.eye_color_id)[
          this.$_getlocaleParam() === "en" ? "name" : "name_jpn"
        ];
      }
      return "";
    },
    hairColor() {
      if (this.getHairColors) {
        return this.getHairColors.find((x) => x.id === this.form.model.hair_color_id)[
          this.$_getlocaleParam() === "en" ? "name" : "name_jpn"
        ];
      }
      return "";
    },
    accountClassification() {
      if (this.getAccountClassifications) {
        return this.getAccountClassifications.find(
          (x) => x.id === this.form.model.account_classification_id
        )[this.$_getlocaleParam() === "en" ? "name" : "name_jpn"];
      }
      return "";
    },
    languagesWithLevel() {
      let list = this.form.languagesWithLevel.map(({ id, level_id }) => {
        return {
          level: this.getLanguageLevels.find((x) => x.id === level_id),
          language: this.getLanguages.find((x) => x.id === id),
        };
      });
      return list;
    },
  },
  methods: {
    ...mapActions("gender", ["loadGenders"]),
    ...mapActions("ethnic", ["loadEthnics"]),
    ...mapActions("language", ["loadLanguages"]),
    ...mapActions("languageLevel", ["loadLanguageLevels"]),
    ...mapActions("eyeColor", ["loadEyeColors"]),
    ...mapActions("hairColor", ["loadHairColors"]),
    ...mapActions("accountClassification", ["loadAccountClassifications"]),
    listByIds(listIds, listItems) {
      let list = [];
      if (listIds) {
        list = listIds.map((id) => listItems.find((x) => x.id === id));
      }
      console.log(list);
    },
    getNameByid(id, list) {
      let name = "";
      if (id) {
        name = list.find((x) => x.id === id)[
          this.$_getlocaleParam() === "en" ? "name" : "name_jpn"
        ];
      }
      return name;
    },
    async sendRequest() {
      await this.$emit("update", (status) => {
        this.sendLoading = status;
      });
    },
  },
  created() {
    this.loadGenders();
    this.loadEthnics();
    this.loadLanguages();
    this.loadLanguageLevels();
    this.loadEyeColors();
    this.loadHairColors();
    this.loadAccountClassifications();
  },
};
</script>
<style scoped>
/* basic info image */
.wa__basic__info__image {
  height: auto;
  padding: 5px;
  border: 1px solid var(--color-light-gray);
  border-radius: 6px;
}
.wa__basic__info__image__dropzone {
  height: 230px;
  padding: 5px;
  border: 1px solid var(--color-light-gray);
  border-radius: 6px;
}
.wa__basic__info__image--image {
  height: 100%;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
.items {
  flex-wrap: wrap;
}
.items .item {
  width: 16.6666666667%;
  margin-bottom: 20px;
}
.items .item:nth-child(6n) {
  padding: 0 !important;
}

.request__details__information {
  display: flex;
}
/* .request__details__information__line::before {
  height: 20px;
  background-color: var(--color-light-gray);
  content: "";
  display: block;
  width: 1px;
  margin: 7px 0;
} */
.request__details__information__line {
  border-left: 1px solid var(--color-light-gray);
  padding-left: 7px;
}
.request__details__information--title {
  color: var(--color-smoky);
  font-size: 12px;
  font-family: "Montserrat-ExtraLight";
}
.request__details__information--sub-title {
  flex-wrap: wrap;
}
.request__details__information--sub-title--block {
  font-family: "Montserrat-regular";
}

.btn-next {
  padding-right: 85px !important;
  padding-left: 85px !important;
  font-size: 0.825rem !important;
}

.btn-prev {
  font-size: 0.825rem !important;
}
@media screen and (max-width: 1260px) {
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .items .item {
    width: 20%;
    margin-bottom: 20px;
  }
  .items .item:nth-child(5n) {
    padding: 0 !important;
  }
  .items .item:nth-child(6n) {
    padding-right: 12px !important;
  }
}
@media screen and (max-width: 960px) {
  .wa__basic__info__image {
    width: 200px;
    margin: 0 auto;
  }
  .btn-prev {
    font-size: 16px !important;
    letter-spacing: 2px !important;
  }
  .btn-next {
    padding-right: 12px !important;
    padding-left: 12px !important;
    font-size: 0.625rem !important;
    width: -webkit-fill-available;
  }
  .w-sm-100 {
    width: 100% !important;
  }

  .items .item {
    width: 25%;
    margin-bottom: 20px;
  }

  .items .item:nth-child(4n) {
    padding: 0 !important;
  }
  .items .item:nth-child(5n) {
    padding-right: 12px !important;
  }
}
@media (max-width: 768px) {
  .items .item {
    width: 33.33333%;
    margin-bottom: 20px;
  }
  .items .item:nth-child(3n) {
    padding: 0 !important;
  }
  .items .item:nth-child(4n) {
    padding-right: 12px !important;
  }
}
@media screen and (max-width: 600px) {
  .flex-dicoration-column-reverse {
    flex-direction: column-reverse;
  }
  .btn-prev {
    letter-spacing: 3px !important;
  }

  .items {
    justify-content: center;
  }
  .items .item {
    width: 100%;
    margin-bottom: 10px;
    padding: 0 !important;
  }
  .items .item:nth-child(3n),
  .items .item:nth-child(4n),
  .items .item:nth-child(5n),
  .items .item:nth-child(6n) {
    padding-right: 0 !important;
  }
}
</style>
