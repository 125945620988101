<template>
  <v-form ref="contactForm" @submit.prevent="submit">
    <div class="padding">
      <v-row>
        <!-- <v-col cols="3" lg="3" class="pr-10 pl-5 d-none d-lg-block">
          <div class="w-100 position-relative mt-2 profile__image">
            <img
              :src="sideImage"
              alt="super model image"
              class="h-100 w-100 rounded"
              style="object-fit: cover"
            />
            <div class="profile__image--label text-uppercase position-absolute">
              super model:
            </div>
            <div class="profile__image--text text-white position-absolute">
              jessi Taylor
            </div>
          </div>
        </v-col>
        <v-col cols="12" lg="9" class="pr-6"> -->
        <v-col cols="12">
          <SectionTitle :title="`${$_t('step')} 2: ${$_t('step2.text')}`" />
          <div class="pt-8 pt-md-5">
            <v-row>
              <v-col cols="12" sm="6" class="py-0 pt-md-3 pb-0">
                <v-text-field
                  class="pt-0 mt-0"
                  :label="$_t('emailAddress')"
                  v-model="form.email"
                  disabled
                  color="SonicSilver"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3" class="py-0 pt-md-3 pb-0">
                <v-text-field
                  class="pt-0 mt-0"
                  :label="$_t('cellPhone')"
                  color="SonicSilver"
                  v-model="form.cell_phone"
                  :rules="numericalRules"
                  required
                  type="phone"
                  dense
                  outlined
                ></v-text-field
              ></v-col>
              <v-col cols="12" sm="6" md="3" class="pt-0 pb-3 pt-md-3 pb-0">
                <v-text-field
                  class="pt-0 mt-0"
                  :label="$_t('lineId')"
                  color="SonicSilver"
                  v-model="form.model.line_id"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <!-- <v-col cols="12" sm="6" md="3" class="py-0 py-md-3">
                </v-col>
              <v-col cols="12" sm="6" md="3" class="mt-n4 mt-md-0 py-0 py-md-3">

              </v-col> -->
              <v-col cols="12" sm="6" md="3" class="pt-0 pb-0 pt-sm-3">
                <v-text-field
                  class="pt-0 mt-0"
                  :label="$_t('instagramId')"
                  color="SonicSilver"
                  v-model="form.model.instagram_id"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3" class="pt-0 pb-3 py-sm-3">
                <v-switch
                  small
                  color="black"
                  class="social__switch mt-0 mt-md-1"
                  v-model="form.model.is_show_follower_count"
                  dense
                  inset
                  outlined
                >
                  <template v-slot:label>{{ $_t("showFollowersCount") }}</template>
                </v-switch>
              </v-col>
              <v-col cols="12" sm="6" md="3" class="pt-0 pb-1 py-md-3">
                <v-text-field
                  class="pt-0 mt-0"
                  :label="$_t('youtubeId')"
                  color="SonicSilver"
                  v-model="form.model.youtube"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3" class="mt-n5 mt-sm-n2 mt-md-0 py-3">
                <v-switch
                  small
                  color="black"
                  class="social__switch mt-0 mt-md-1"
                  v-model="form.model.is_show_subscribe_count"
                  dense
                  inset
                  outlined
                >
                  <template v-slot:label>{{ $_t("showSubscribersCount") }}</template>
                </v-switch>
              </v-col>
            </v-row>
            <ul class="d-flex justify-space-between pl-0 list-unstyled mt-3 mt-md-0">
              <li>
                <v-autocomplete
                  :label="$_t('country')"
                  color="SonicSilver"
                  class="pt-0 mt-0"
                  :items="getCountries"
                  item-value="id"
                  :item-text="$_getlocaleParam()=== 'en'?'name':'name_jpn'"
                  v-model="form.country_id"
                  :rules="requiredRules"
                  required
                  dense
                  outlined
                ></v-autocomplete>
              </li>
              <li>
                <v-text-field
                  :label="$_t('city')"
                  color="SonicSilver"
                  class="pt-0"
                  v-model="form.city"
                  :rules="requiredRules"
                  required
                  dense
                  outlined
                ></v-text-field>
              </li>
              <li class="px-0 pl-sm-1">
                <v-text-field
                  class="pt-0"
                  :label="$_t('address')"
                  color="SonicSilver"
                  :rules="requiredRules"
                  required
                  v-model="form.model.address"
                  dense
                  outlined
                ></v-text-field>
              </li>
            </ul>
            <ul class="d-flex justify-space-between justify-md-start pl-0 list-unstyled">
              <li class="mr-0 mr-md-4">
                <v-text-field
                  class="pt-0"
                  :label="$_t('state')"
                  color="SonicSilver"
                  v-model="form.model.state"
                  :rules="requiredRules"
                  required
                  dense
                  outlined
                ></v-text-field>
              </li>
              <li class="mr-0 mr-md-4">
                <v-text-field
                  class="pt-0"
                  :label="$_t('zipCode')"
                  color="SonicSilver"
                  v-model="form.model.zipcode"
                  :rules="numericalRules"
                  required
                  dense
                  outlined
                ></v-text-field>
              </li>
              <li class="ml-0 mr-md-4">
                <v-text-field
                  class="pt-0"
                  :label="$_t('apartment')"
                  color="SonicSilver"
                  v-model="form.model.apartment"
                  :rules="requiredRules"
                  required
                  dense
                  outlined
                ></v-text-field>
              </li>
              <li>
                <v-text-field
                  class="pt-0"
                  :label="$_t('nearestStation')"
                  color="SonicSilver"
                  v-model="form.model.nearest_station"
                  :rules="requiredRules"
                  required
                  dense
                  outlined
                ></v-text-field>
              </li>
            </ul>
            <v-divider class="mb-2"></v-divider>
          </div>
          <v-row class="flex-dicoration-column-reverse">
            <v-col cols="12" sm="6" class="pt-0 pt-sm-3 text-center text-sm-left">
              <block-button
                :text="$_t('step2.prev')"
                height="30"
                class="btn-prev pl-0"
                icon="WMi-left-open"
                text-mode="text"
                @click.native="$emit('prevStep')"
              ></block-button
            ></v-col>
            <v-col cols="12" sm="6" class="text-right">
              <block-button
                :text="$_t('step2.next')"
                height="30"
                class="btn-next site__button width-auto float-right"
                type="submit"
              ></block-button
            ></v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import SideImage from "../../assets/e9f8de65fff644826d5cb03fa102832b.jpg";
import SectionTitle from "../Global/Section/SectionTitle.vue";
import { UserStorage } from "@/utils/storage";
import {numberRules,requiredRules,numericalRules} from "@/mixins/validate"
export default {
  components: {
    SectionTitle,
  },
  props: ["value"],
  data() {
    return {
      sideImage: SideImage,
      email: "",
    };
  },
  computed: {
    ...mapGetters("country", ["getCountries"]),
    numberRules,
    requiredRules,
    numericalRules,
    form: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapActions("country", ["loadCountries"]),
    submit() {
      if (!this.$refs.contactForm.validate()) {
        return;
      }
      this.$emit("nextStep");
    },
    isVerified() {
      return !!UserStorage.get().model && !!UserStorage.get().model.created_at;
    },
  },
  created() {
    this.loadCountries();
  },
  mounted() {
    if (!this.isVerified() & !this.form.isSetContactInfo) {
      this.form.isSetContactInfo = true;
      this.$refs.contactForm.resetValidation();
    }
  },
};
</script>
<style scoped>
.padding {
  padding-bottom: 100px;
}
.profile__image {
  height: 470px;
}
.profile__image--text {
  bottom: 10px;
  left: 35px;
  letter-spacing: 3px;
  font-family: "Barlow-EL";
}
.profile__image--label {
  font-family: "Montserrat-regular";
  font-size: 9px;
  color: var(--color-gray);
  bottom: 30px;
  left: 35px;
}
ul:nth-child(1) li {
  width: 19%;
}
ul:nth-child(3) li:nth-child(1),
ul:nth-child(3) li:nth-child(2) {
  width: 19%;
}
ul:nth-child(3) li:last-child {
  width: 59.8%;
}
ul:nth-child(4) li {
  width: 19%;
}
.btn-next {
  padding-right: 85px !important;
  padding-left: 85px !important;
  font-size: 0.825rem !important;
}
.btn-prev {
  font-size: 0.825rem !important;
}

@media screen and (max-width: 960px) {
  ul {
    flex-wrap: wrap !important;
  }
  ul:nth-child(4) li {
    width: 49%;
  }
  ul:nth-child(2) li {
    width: 49%;
  }
  ul:nth-child(3) li:nth-child(1),
  ul:nth-child(3) li:nth-child(2) {
    width: 49%;
  }
  ul:nth-child(3) li:last-child {
    width: 100%;
  }
  .btn-prev {
    font-size: 16px !important;
    letter-spacing: 2px !important;
  }
  .btn-next {
    padding-right: 12px !important;
    padding-left: 12px !important;
    font-size: 0.625rem !important;
    width: -webkit-fill-available;
  }
}
@media screen and (max-width: 600px) {
  ul {
    margin-bottom: 0 !important;
  }
  ul li {
    width: 100% !important;
  }
  .flex-dicoration-column-reverse {
    flex-direction: column-reverse;
  }
  .btn-prev {
    letter-spacing: 3px !important;
  }
}
</style>
<style>
.social__switch .v-input__control .v-input__slot .v-label {
  font-size: 13px !important;
}
</style>
